
import { defineComponent } from "vue";
import { useStore } from "vuex";
import MediaLogListCart from "@/components/MediaLogListCard.vue";
import MediaLogFilterChip from "@/components/MediaLogFilterChip.vue";

export default defineComponent({
  name: "MediaLog",
  components: { MediaLogFilterChip, MediaLogListCart },
  computed: {
    lists() {
      const store = useStore();

      return store.getters["mediaLog/getLists"];
    },
    handleFilter: {
      get() {
        return true;
      },
      set(filter: string) {
        const store = this.$store;

        store.dispatch("mediaLog/handleFilter", filter);
      },
    },
  },
  mounted() {
    const store = this.$store;

    store.dispatch("mediaLog/setListsAndItems");
  },
  methods: {},
});
