
import ResumeSkillSection from "@/components/ResumeSkillSection.vue";
import { mapGetters } from "vuex";

export default {
  name: "ResumeLayout",
  components: { ResumeSkillSection },
  props: {},
  computed: {
    ...mapGetters({
      experience: "experienceStore/getExperiences",
      skills: "experienceStore/getSkills",
      frameworks: "experienceStore/getFrameworks",
      design: "experienceStore/getDesign",
      projectMgmt: "experienceStore/getProjectMgmt",
    }),
  },
};
