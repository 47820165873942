import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid md:grid-cols-2 gap-4 flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResumeCard = _resolveComponent("ResumeCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.experiences, (experience, expIndex) => {
      return (_openBlock(), _createBlock(_component_ResumeCard, {
        key: `experience-web-${expIndex}`,
        "sub-head": experience.company,
        title: experience.position,
        subtitle: experience.time,
        achievements: experience.achievements,
        time: experience.time
      }, null, 8, ["sub-head", "title", "subtitle", "achievements", "time"]))
    }), 128))
  ]))
}