import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createBlock(_component_v_chip, {
    color: _ctx.chipEnabled ? _ctx.color : 'grey',
    class: "mr-2 mt-2",
    variant: _ctx.selected ? 'elevated' : 'outlined',
    onClick: _ctx.selectFilter
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.chipValue), 1)
    ]),
    _: 1
  }, 8, ["color", "variant", "onClick"]))
}