
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "ResumeCard",
  props: {
    subHead: String,
    title: String,
    subtitle: String,
    achievements: {
      type: Array,
      required: false,
    },
    time: String,
  },
  computed: {
    skillGroups() {
      const skills = useStore().getters[
        "experienceStore/getExperienceSkillsByTime"
      ](this.time);

      if (skills) {
        return [
          { skills: skills.frameworks, color: "red" },
          { skills: skills.design, color: "green" },
          { skills: skills.projectMgmt, color: "blue" },
        ];
      }
      return undefined;
    },
  },
});
