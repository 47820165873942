import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItems, (listItem, index) => {
    return (_openBlock(), _createBlock(_component_v_list, {
      key: `lists-${listItem.id}-item-${index}`
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list_item, {
          title: listItem.name,
          subtitle: listItem.dateLastActivity
        }, null, 8, ["title", "subtitle"])
      ]),
      _: 2
    }, 1024))
  }), 128))
}