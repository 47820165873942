
import { defineComponent } from "vue";
import ResumeFilterChip from "@/components/ResumeFilterChip.vue";

export default defineComponent({
  name: "ResumeFilterSection",
  components: { ResumeFilterChip },
  props: {
    entryArray: Array,
    title: String,
    color: String,
  },
  data() {
    return {
      selected: false,
    };
  },
});
