import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResumeFilterChip = _resolveComponent("ResumeFilterChip")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createBlock(_component_v_list_item, { title: _ctx.title }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entryArray, (entry) => {
          return (_openBlock(), _createBlock(_component_ResumeFilterChip, {
            color: _ctx.color,
            selected: _ctx.selected,
            "chip-value": entry,
            key: `${entry}-filter`
          }, null, 8, ["color", "selected", "chip-value"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}