import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid gird-cols-1 md:grid-cols-3 gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MediaLogFilterChip = _resolveComponent("MediaLogFilterChip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_MediaLogListCart = _resolveComponent("MediaLogListCart")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_chip_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_MediaLogFilterChip)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists, (list, index) => {
          return (_openBlock(), _createBlock(_component_MediaLogListCart, {
            key: `list-${index}`,
            list: list
          }, null, 8, ["list"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}