
import { defineComponent } from "vue";

import ResumeLayout from "@/components/ResumeLayout.vue";
import ResumeWebLayout from "@/components/ResumeWebLayout.vue";
import ResumeBottomNav from "@/components/ResumeBottomNav.vue";

export default defineComponent({
  name: "ResumeTemplate",
  components: { ResumeBottomNav, ResumeWebLayout, ResumeLayout },
  methods: {
    /*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
    printResume() {
      window.print();
    },
  },
});
