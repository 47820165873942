import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_MediaLogList = _resolveComponent("MediaLogList")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: _normalizeClass(_ctx.visibilityStatus ? '' : 'hidden')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.list.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_MediaLogList, {
        "list-id": _ctx.list.id
      }, null, 8, ["list-id"])
    ]),
    _: 1
  }, 8, ["class"]))
}