
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import ResumeCard from "@/components/ResumeCard.vue";

export default defineComponent({
  name: "ResumeExperienceCards",
  components: { ResumeCard },
  computed: {
    ...mapGetters({
      experiences: "experienceStore/getFilteredExperiences",
    }),
  },
});
