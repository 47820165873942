import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76f49cef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResumeLayout = _resolveComponent("ResumeLayout")!
  const _component_ResumeWebLayout = _resolveComponent("ResumeWebLayout")!
  const _component_ResumeBottomNav = _resolveComponent("ResumeBottomNav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ResumeLayout, { class: "hidden print:block" }),
    _createVNode(_component_ResumeWebLayout, { class: "print:hidden z-10" }),
    _createVNode(_component_ResumeBottomNav, {
      class: "print:hidden",
      "print-resume": _ctx.printResume
    }, null, 8, ["print-resume"])
  ]))
}