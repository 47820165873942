import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createBlock(_component_v_chip, {
    modelValue: _ctx.handleFilter,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.handleFilter) = $event))
  }, {
    default: _withCtx(() => [
      _createTextVNode("All")
    ]),
    _: 1
  }, 8, ["modelValue"]))
}