import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.links.main, (link, index) => {
        return (_openBlock(), _createBlock(_component_v_list_item, {
          key: `mobile-link-main-${index}`
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: link.url
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(link.name), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}