
import { defineComponent } from "vue";
import { useStore } from "vuex";
import MobileLinks from "@/MobileLinks.vue";
import SocialLink from "@/views/SocialLink.vue";

export default defineComponent({
  name: "AppNav",
  components: { SocialLink, MobileLinks },
  data() {
    return {
      navOpen: false,
      links: {
        main: [
          {
            name: "About",
            url: "/about",
          },
          {
            name: "Resume",
            url: "/resume",
          },
        ],
        side: [
          {
            name: "Block Hopper",
            url: "/block-hopper",
          },
          {
            name: "Posts",
            url: "/posts",
          },
        ],
      },
    };
  },
  computed: {
    drawerOpen() {
      const store = useStore();

      return store.getters["mediaLog/getNavStatus"];
    },
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen;
    },
  },
});
