<template>
  <div class="contents">
    <v-btn variant="plain" href="https://github.com/AndyWrecks" target="_blank">
      <font-awesome-icon
        class="text-3xl"
        icon="fa-brands fa-github"
      ></font-awesome-icon>
    </v-btn>
    <v-btn
      variant="plain"
      href="https://www.linkedin.com/in/andisreks/"
      target="_blank"
    >
      <font-awesome-icon
        class="text-3xl"
        icon="fa-brands fa-linkedin"
      ></font-awesome-icon>
    </v-btn>
    <v-btn variant="plain" href="mailto:andiskreks@gmail.com" target="_blank">
      <font-awesome-icon
        class="text-3xl"
        icon="fa-solid fa-envelope"
      ></font-awesome-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "SocialLink",
};
</script>
