import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row justify-between md:space-x-4 mt-4"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "grid md:grid-cols-3 gap-4 flex-grow my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_ResumeFilters = _resolveComponent("ResumeFilters")!
  const _component_ResumeExperienceCards = _resolveComponent("ResumeExperienceCards")!
  const _component_ResumeCard = _resolveComponent("ResumeCard")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_btn_toggle, {
        modelValue: _ctx.activeSection,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeSection) = $event)),
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, null, {
            default: _withCtx(() => [
              _createTextVNode(" Experience ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, null, {
            default: _withCtx(() => [
              _createTextVNode(" Education ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.activeSection === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ResumeFilters, { class: "hidden md:block md:max-w-xs flex-grow" }),
            _createVNode(_component_ResumeExperienceCards)
          ]))
        : _createCommentVNode("", true),
      (_ctx.activeSection === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ResumeCard, {
                "sub-head": "Rochester Institute of Technology",
                title: "Media Arts and Technology (B.S.)",
                subtitle: "2009 - 2013",
                time: "2009 - 2013"
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}