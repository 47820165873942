
import { useStore } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MediaLogList",
  props: {
    listId: String,
  },
  computed: {
    listItems: function () {
      const store = useStore();
      return store.getters["mediaLog/getListItems"](this.listId);
    },
  },
});
