import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid print:grid-cols-4 md:grid-cols-4 grid-cols-2 pr-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", null, _toDisplayString($props.title), 1),
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.skills, (skill) => {
        return (_openBlock(), _createElementBlock("li", { key: skill }, _toDisplayString(skill), 1))
      }), 128))
    ])
  ]))
}