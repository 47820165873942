
import { defineComponent } from "vue";
import ResumeFilters from "@/components/ResumeFilters.vue";

export default defineComponent({
  name: "ResumeBottomNav",
  components: { ResumeFilters },
  props: {
    printResume: {},
  },
});
