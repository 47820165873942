
import { defineComponent } from "vue";
import ResumeFilterSection from "@/components/ResumeFilterSection.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ResumeFilters",
  components: { ResumeFilterSection },
  computed: {
    ...mapGetters({
      frameworks: "experienceStore/getAvailableFrameworks",
      design: "experienceStore/getAvailableDesign",
      projectMgmt: "experienceStore/getAvailableMgmt",
    }),
  },
});
