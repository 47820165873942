import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col md:space-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResumeFilterSection = _resolveComponent("ResumeFilterSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ResumeFilterSection, {
      "entry-array": _ctx.frameworks,
      title: "Tech",
      color: "red"
    }, null, 8, ["entry-array"]),
    _createVNode(_component_ResumeFilterSection, {
      "entry-array": _ctx.design,
      title: "Design",
      color: "green"
    }, null, 8, ["entry-array"]),
    _createVNode(_component_ResumeFilterSection, {
      "entry-array": _ctx.projectMgmt,
      title: "Project Management",
      color: "blue"
    }, null, 8, ["entry-array"])
  ]))
}