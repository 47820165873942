
import { defineComponent } from "vue";
import { useStore } from "vuex";
import ResumeFilters from "@/components/ResumeFilters.vue";
import ResumeExperienceCards from "@/components/ResumeExperienceCards.vue";
import ResumeCard from "@/components/ResumeCard.vue";

export default defineComponent({
  name: "ResumeWebLayout",
  components: { ResumeCard, ResumeExperienceCards, ResumeFilters },
  data() {
    return {
      activeSection: 0,
    };
  },
  computed: {},
  beforeMount() {
    const store = useStore();

    store.dispatch("experienceStore/setAvailableFilters");
  },
});
